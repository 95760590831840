.presentation-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

.css-1m9128y {
  display: none !important;
}

.presentation-container div {
  width: 100%;
  height: 100%;
}
