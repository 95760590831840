.header-detail-meeting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 0.7px solid #c3c3c3;
  &-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

.meeting-tab-options {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

.share-meeting-tab {
  font-size: 10px !important;
  padding: -2px !important;
  background: #404040 !important;
  color: #fff !important;
  width: auto !important;
  height: auto !important;
  font-weight: bold !important;
  &:hover {
    background: #696969 !important;
    color: rgb(255, 255, 255) !important;
  }
}

.edit-meeting-tab,
.delete-meeting-tab {
  font-size: 10px !important;
  padding: -2px !important;
  border: 1px solid#404040 !important;
  color: #404040 !important;
  width: auto !important;
  height: auto !important;
  font-weight: bold !important;
  &:hover {
    background: #bbbbbb !important;
    color: #404040 !important;
  }
}
