.dashboard-card-meeting-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  }
  &-header {
    padding: 0px 5px 10px 5px;
  }
  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.copy-share-btn {
  font-size: 14px !important;
  border: 1px solid #b11e55 !important;
  font-weight: 600 !important;
}

.start-meeting-btn {
  font-size: 14px !important;
  background-color: #b11e55 !important;
  color: #fff !important;
  font-weight: 600 !important;
  &:hover {
    background-color: #b95c7e !important;
    color: rgb(195, 193, 193) !important;
  }
}
